import React from 'react'
import { graphql } from 'gatsby'
import BlogPage from './blogPage'
import { LayoutContext } from '../layout'
import linkResolver from '../../contentful/linkResolver'
import moment from 'moment'
import 'moment/locale/sv'
import localizationFilter from '../../contentful/localizationFilter'

const BlogPageContainer = ({ data, pageContext }) => {
  const {
    contentfulBlogPage,
    contentfulBlogArticles,
    allContentfulCategory
  } = data
  const { breadcrumbs, locale } = pageContext

  
  const categorySlug = pageContext.category.replace(/^[/]+|[/]+$/g, ''); 
  const { localization } = React.useContext(LayoutContext)

  moment.locale(contentfulBlogPage.node_locale.slice(0, 2))

  const blogPage = {
    _doc: contentfulBlogPage,
    path: linkResolver(contentfulBlogPage),
    locale: contentfulBlogPage.node_locale,
    metaTitle: contentfulBlogPage.metaTitle || contentfulBlogPage.heading,
    metaDescription: contentfulBlogPage.metaDescription,
    metaOgImage: contentfulBlogPage.listImage
      ? contentfulBlogPage.listImage.fluid
      : null,
    heading: contentfulBlogPage.heading,
    published: moment(contentfulBlogPage.published).format('LL'),
    topImage: contentfulBlogPage.topImage
    ? contentfulBlogPage.topImage.fluid
    : null
  }

  const posts = contentfulBlogArticles.edges
    .filter(localizationFilter)
    .map(({ node }) => {
      return {
        link: linkResolver(node),
        published: moment(node.published).format('LL'),
        listText: node.listText ? node.listText.listText : null,
        heading: node.heading,
        image: node.listImage ? node.listImage.fluid : null,
        category: node.category.name
      }
    })

  const categories = mapCategories(
    allContentfulCategory.edges,
    categorySlug,
    contentfulBlogPage.slug,
    locale
  )

  return (
    <BlogPage
      doc={blogPage}
      posts={posts}
      categories={categories}
      localization={localization}
      totalCount={pageContext.totalCount}
      category={categorySlug}
      pageNo={pageContext.currentPage}
      crumbs={breadcrumbs}
    />
  )
}

function mapCategories(categories, selectedCategory, slug, locale) {
  let array = categories.map(({ node }) => {
    return {
      name: node.name,
      link: `${locale !== 'sv-SE' ? '/' + locale.slice(0,2) : ''}/${slug}/${node.slug}`,
      selected: selectedCategory === node.slug
    }
  })
  array.unshift({
    name: 'Alla',
    link: `${locale !== 'sv-SE' ? '/' + locale.slice(0,2) : ''}/${slug}`,
    selected: selectedCategory === ''
  })
  return array
}

export default BlogPageContainer

export const pageQuery = graphql`
  query($locale: String!, $slug: String!, $limit: Int!, $skip: Int!, $category: String!) {
    contentfulBlogPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      heading
      slug
      metaTitle
      metaDescription
      topImage {
        fluid(maxWidth: 2000, maxHeight: 500, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
    }
    contentfulBlogArticles: allContentfulPage(
      filter: { pageType: { in: ["Blog post"] }, node_locale: { eq: $locale }, category: {slug: {regex: $category}} }
      limit: $limit
      skip: $skip
      sort: { fields: [published], order: DESC }
    ) {
      edges {
        node {
          node_locale
          enableLocalization
          slug
          heading
          published
          category {
            name
            slug
          }
          listText {
            listText
          }
          pageType
          listImage {
            fluid(maxWidth: 405, maxHeight: 387, quality: 80) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulCategory(
      sort: { order: ASC, fields: name }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
