import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import './tabs.scss'

function Tabs({ items }) {
  useEffect(() => {
    if (window) {
      //clear location state to preserve scroll when navigating back from article.
      window.history.replaceState('', null)
    }
  })

  let html = items.map((item, i) => {
    return (
      <Link
        state={{ scrollTo: 'blog-top' }}
        to={item.link}
        className={item.selected ? 'filter__active' : ''}
        key={i}
      >
        {item.name}
      </Link>
    )
  })

  return (
    <nav aria-label="Kategorier" className="filter">
      <div>
        <div className="filter__inner">{html}</div>
      </div>
    </nav>
  )
}

export default Tabs
