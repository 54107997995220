import React from 'react'
import { Link } from 'gatsby'
import './pagination.scss'

function Pagination({ numPages, currentPage, path }) {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? path : path + (currentPage - 1).toString()
  const nextPage = path + (currentPage + 1).toString()


  return (
    <div className="pagination">
      <div className="pagination__prev">
        {!isFirst && (
          <Link
            className="button button--subtle button--smaller"
            to={prevPage}
            rel="prev"
          >
            Föregående
          </Link>
        )}
      </div>
      <div className="pagination__numbers">
        {Array.from({ length: numPages }, (_, i) => (
          <Link
            className={
              i + 1 === currentPage
                ? `button button--tiny`
                : `button--subtle button button--tiny`
            }
            key={i}
            to={path + (i === 0 ? '' : i + 1)}
          >
            {i + 1}
          </Link>
        ))}
      </div>
      <div className="pagination__next">
        {!isLast && (
          <Link
            className="button button--subtle button--smaller"
            to={nextPage}
            rel="next"
          >
            Nästa
          </Link>
        )}
      </div>
    </div>
  )
}

export default Pagination
