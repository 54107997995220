import React from 'react'

function Main({ children }) {
  return (
    <main>
      {children}
    </main>
  )
}
export default Main
