import React from 'react'
import Main from '../components/main'
import ContentWrapper from '../components/contentWrapper'
import MailChimp from '../components/mailChimp'
import ArticleTeasers from '../components/articleTeasers'
import Pagination from '../components/pagination'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import { LayoutContext } from '../layout'
import '../styles/patterns/largeHeader.scss'
import '../styles/patterns/filter.scss'
import Tabs from '../components/tabs'
import Seo from '../components/seo'
import Img from 'gatsby-image'

const BlogPage = ({ doc, posts, categories, totalCount, category, pageNo }) => {
  const { localization, langSlug, setBodyClass } = React.useContext(
    LayoutContext
  )

  //Temporary fix (https://github.com/facebook/react/issues/18178)
  setTimeout(() => {
    setBodyClass('blog')
  })

  return (
    <React.Fragment>
      <Main>
        <Seo
          title={doc.metaTitle}
          description={doc.metaDescription}
          ogImage={doc.metaOgImage}
          ogType="website"
          url={doc.path}
        />
        <div
          className={
            doc.topImage ? 'large-header large-header--bg' : 'large-header'
          }
        >
          <div className="large-header__inner">
            <ContentWrapper modifier="wide">
              <div className="flex-center-column">
                <h1 className="heading-1 heading-1--larger heading-1--blue">
                  {doc.heading}
                </h1>
                {doc.introduction && (
                  <div className="list-text list-text--larger">
                    <p>{doc.introduction}</p>
                  </div>
                )}
              </div>
            </ContentWrapper>
          </div>
          {doc.topImage && (
            <ParallaxProvider>
              <div className="large-header__overlay"></div>
              <Parallax
                className="large-header__parallax"
                y={[-20, 20]}
                tagOuter="div"
              >
                <Img
                  fluid={doc.topImage}
                  placeholderClassName="large-header__bg lazy"
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: '-40px',
                    bottom: '-40px',
                    right: 0
                  }}
                />
              </Parallax>
            </ParallaxProvider>
          )}
        </div>
        {/* <div className="large-header" id="blog-top">
          <ContentWrapper modifier="wide">
            <div className="large-header__inner flex-center">
              <h1 className="heading-1 heading-1--larger heading-1--blue">
                {doc.heading}
              </h1>
              <div className="list-text list-text--larger">
                <p className="center">{doc.introduction}</p>
              </div>
            </div>
          </ContentWrapper>
        </div> */}
        <ContentWrapper modifier="wide">
          <div className="flex-center" id="filter">
            <Tabs items={categories} />
          </div>
          <ArticleTeasers articles={posts} addPlaceholder={true} />
          <Pagination
            currentPage={pageNo}
            numPages={Math.ceil(totalCount / 9)}
            path={`${langSlug}/${localization.blog_slug}/${
              category ? category + '/' : ''
            }`}
          />
        </ContentWrapper>
      </Main>
      <ContentWrapper modifier="bg">
        <MailChimp />
      </ContentWrapper>
    </React.Fragment>
  )
}

export default BlogPage
